<template>
  <div class="weui-cell" :class="disabled!==undefined?'disabled':''">
    <div class="weui-cell__bd">{{ text }}</div>
    <div class="weui-cell__ft">
      <slot></slot>
    </div>
    
  </div>
</template>

<script>
import mixins from "@/mixins/form";
export default {
  props: ["text","disabled"],
  data() {
    return {
      
    };
  },
};
</script>

<style >
.weui-cell__ft{
    display: flex;
    align-items: center;
}
.disabled{
    opacity: 0.5;
}

</style>