<template>
  <section class="container">
    <div class="board">
      <section class="header">
        <h5>{{ info.name }}</h5>
        <p>
          <span>{{ info.mobile }}</span>
          <span>{{
            info.type == 0
              ? "自有员工"
              : info.type == 1
              ? "外聘员工"
              : "务工员工"
          }}</span>
          <span>{{ info.gender }}</span>
        </p>
      </section>
      <section class="row">
        <p>所属机构：{{ info.institution }}</p>
      </section>
      <section class="row" v-if="info.country">
        <p>所属国别：{{ info.country }}</p>
      </section>
      <section class="row" v-if="info.project">
        <p>所属项目：{{ info.project }}</p>
      </section>
      <section class="row">
        <p>
          {{ info.alwaysCountry }} {{ info.province }} {{ info.city }}
          {{ info.district }}
        </p>
        <p>{{ info.addressDetail }}</p>
      </section>
    </div>
    <div class="body">
      <div class="weui-cells__title">体温信息</div>
      <div class="weui-cells">
        <acell text="体温">
          <span style="color: #333">{{
            temp[0] ? temp[0].temperature : ""
          }}</span>
          <span style="width: 140px" @click="link('temp')">体温历史</span>
        </acell>
        <acell text="基础病史">
          <span>{{
            temp[0] && temp[0].abnormal ? temp[0].abnormal.name : "无"
          }}</span>
        </acell>
      </div>

      <div class="weui-cells__title">隔离信息</div>
      <div class="weui-cells">
        <acell text="隔离状态">
          <span style="color: red">{{
            isolate[0] ? isolate[0].isolateType : ""
          }}</span>
          <span style="width: 140px" @click="link('isolate')">隔离历史</span>
        </acell>
      </div>

      <div class="weui-cells__title">疫苗接种</div>
      <div class="weui-cells">
        <acell text="是否接种疫苗">
          <span style="color: red">{{ vaccine.length ? "是" : "否" }}</span>
          <span style="width: 150px" @click="link('vaccine')">接种历史</span>
        </acell>
      </div>

      <div class="weui-cells__title">核酸检测</div>
      <div class="weui-cells">
        <acell :text="nuclear[0] ? nuclear[0].time.substr(0, 10) : ''">
          <span style="color: red">{{
            nuclear[0] ? (nuclear[0].result == 0 ? "阴性" : "阳性") : ""
          }}</span>
          <span style="width: 150px" @click="link('nuclear')">检测历史</span>
        </acell>
      </div>
      <div class="weui-cells__title">行程记录</div>
      <div style="padding: 20px">
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in history"
            :key="index"
            :timestamp="item.goTime.substr(0, 10)"
          >
            <div class="tit">
              <section>
                <span
                  >目的地：{{
                    (item.abroadName ? item.abroadName : "中国") +
                    (item.province || "") +
                    (item.city || "") +
                    (item.district || "") +
                    item.aimAddress
                  }}</span
                >
                <p>
                  交通工具：{{
                    item.tripWayId == 2
                      ? "飞机"
                      : item.tripWayId == 1
                      ? "火车"
                      : "其他"
                  }}
                </p>
                <p>班次：{{ item.tripNo }}</p>
                <p>出行原因：{{ item.tripReason }}</p>
              </section>
              <!-- <div style="display: flex; justify-content: space-between">
                <span class="edit" @click="edit(item)">
                  <i class="el-icon-edit-outline"></i>
                </span>
                <span class="del" @click="del(item)">
                  <i class="el-icon-delete"></i>
                </span>
              </div> -->
            </div>
          </el-timeline-item>
        </el-timeline>
        <div @click="history = allHistory;showFlag=false" v-if="allHistory.length>3 && showFlag" style="color:#999;text-align:center">点击查看全部</div>
      </div>
    </div>
  </section>
</template>

<script>
import acell from "@/components/form/cell";
import {
  getPersonDetail,
  getTempDetail,
  getIsolateDetail,
  getVaccineDetail,
  getNuclearDetail,
  tripList,
} from "@/api/api";
export default {
  data() {
    return {
      info: {},
      form: {},
      temp: [],
      isolate: [],
      vaccine: [],
      nuclear: [],
      tempFlag: false,
      nuclearFlag: false,
      vaccineFlag: false,
      isolateFlag: false,
      history: [],
      allHistory: [],
      showFlag:true
    };
  },
  mounted() {
    let mobile = this.$route.query.mobile;
    if (mobile) {
      this.getUserDetail(mobile);
      this.getTempDetail(mobile);
      this.getIsolateDetail(mobile);
      this.getVaccineDetail(mobile);
      this.getNuclearDetail(mobile);
      this.getHistoryList(mobile);
    }
  },
  methods: {
    async getUserDetail(phone) {
      let res = await getPersonDetail(phone);
      this.info = res.data.data;
      document.title = this.info.name;
    },
    getHistoryList(mobile) {
      tripList({ mobile: mobile }).then((res) => {
        this.history = res.data.data.slice(0, 3);
        this.allHistory = res.data.data;
      });
    },
    async getTempDetail(phone) {
      let res = await getTempDetail(phone);
      this.temp = res.data.data;
    },
    async getIsolateDetail(phone) {
      let res = await getIsolateDetail(phone);
      this.isolate = res.data.data;
    },
    async getVaccineDetail(phone) {
      let res = await getVaccineDetail(phone);
      this.vaccine = res.data.data;
    },
    async getNuclearDetail(phone) {
      let res = await getNuclearDetail(phone);
      this.nuclear = res.data.data;
    },
    showTemp() {
      this.tempFlag = !this.tempFlag;
    },
    link(type) {
      this.$router.push(`/detailList/${this.info.mobile}?type=${type}`);
    },
  },
  components: {
    acell,
  },
};
</script>

<style scoped>
.board {
  background: #2f7dcd;
  padding: 0.1rem 0.2rem;
}
.board > section {
  padding: 0.1rem 0rem;
  color: #fff;
}
.header h5 {
  font-size: 0.28rem;
  font-weight: normal;
}
section p {
  font-size: 0.15rem;
  line-height: 1.8;
}
section p span {
  margin-right: 0.1rem;
}
.row {
  border-top: 1px dotted #fff;
}
</style>